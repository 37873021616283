import React from 'react'
import Dashboard from './component/Dashboard';
import Dashboard2 from './component/DashboardNew';
import Adminpage from './component/Adminpage';
import { BrowserRouter, Routes, Route,useNavigate} from "react-router-dom";

function App() {
  // const navigate=useNavigate();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Adminpage/> } />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard2" element={<Dashboard2 />} />
        <Route path="/" to='/login' element={<Adminpage />} />
        {!localStorage.getItem('kanaswapToken') && 
        <>
          
        </>
          
        }
      </Routes>
    </BrowserRouter>


  );
}

export default App;
