import React from 'react';
import kanalabslogo from '../Assests/kanalabslogo.png';
import { useState, useEffect } from 'react';
import {useNavigate} from "react-router-dom"
import { userLogin } from '../Utiles/Helper';
import {ToastContainer,  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Adminpage = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
 
    const login=async()=>{
        if(!email){
            console.log("email enter")
            toast.warning("please provide valid email")
        }
        if(!password){
            toast.warning("please provide valid password")
        }if(email && password){
            let logindata=await userLogin({email:email,password:password})
            console.log("logindata",logindata)
            if(logindata.error){
                toast.error(logindata?.message)
            }else{
                localStorage.setItem('kanaswapToken',logindata.data)
                navigate('/dashboard')
            }
            
        }
        //hide this else once login integration done
        // else{
        //     navigate('/dashboard')
        // }
        
    }


    return (
        <div className='w-100%'>
            <div className='flex justify-center mt-5'>
                <img src={kanalabslogo} alt='' className='mt-5 items-center' />
            </div>
            <div className='flex justify-center'>
                <card className='border-collapse rounded border-solid border-slate-900'>
                    <h1 className='text-6xl text-center text-white font-rajd mt-28'>Admin </h1>
                    <p className='text-1xl text-center text-white mt-3 font-raj'>Enter Your Login id and Password</p>
                    <input type="text" placeholder='Please enter email' className='flex justify-center rounded-lg w-72 h-10 mt-2' onChange={(e)=>{
                        setEmail(e.target.value)
                    }}></input><br></br>
                    <input type="password" placeholder='Password' className='flex justify-center rounded-lg w-72 h-10' onChange={(e)=>{
                        setPassword(e.target.value)
                    }}></input>
                    <button className='text-1xl text-[black] bg-green-300 px-6 py-2 mx-24 rounded-full mt-10 font-semibold loo' onClick={login}>Login</button>
                    <ToastContainer />

                </card>
            </div>


        </div >
    )
}

export default Adminpage
