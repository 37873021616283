import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import IconDownload from "../Assests/download.png";

const Table = (props) => {

    const [showExport, setShowExport] = useState(false);
    const [exportFilename, setExportFilename] = useState(null);
    
    useEffect(() => {
        const start = props.dateRange.start.toString().replaceAll("-", "");
        const end = props.dateRange.end.toString().replaceAll("-", "");
        setExportFilename(`${start}-${end}`);
    })
    
    return (
        <React.Fragment>
            <div className="table-wrapper h-80 overflow-clip w-fit mx-auto">
                <table className="table-auto block h-full overflow-y-scroll overflow-x-hidden">
                    <thead className="sticky top-0 bg-primary">
                        <tr className="text-secondary">
                            {props.columns.map(column => (
                                <td key={column.key} className="p-2 bg-secondary text-primary opacity-60">{column.label}</td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {props.rows.map(row => (
                            <tr className="border-2">
                                {props.columns.map(column => (
                                    <td className="p-2 border-2 border-neutral-700">{row[column.dataIndex]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <CSVLink 
                data={props.rows} 
                headers={props.columns}
                filename={exportFilename}
                className="absolute bottom-0 right-0 self-end flex items-center bg-slate-500 p-1 z-10 opacity-50 hover:opacity-100 transition-all"
                onMouseEnter={() => setShowExport(true)}
                onMouseLeave={() => setShowExport(false)}
            >
                <img src={IconDownload} alt="Download icon" className="h-4 m-1" />
                <span className={(showExport ? "inline-block" : "hidden") + " text-xs"}>Export as CSV</span>
            </CSVLink>
        </React.Fragment>
    )
}
export default Table;