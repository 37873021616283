// data: array of objects; 
// aggregateBy: column name (string) to aggregate by (date column)
// columnsToAggregate: array of column keys to group/aggregate
export const GroupDataByDate = (data) => {
    let grouped = [];
    data.map((tx) => {
        const txDate = fromISOToLocaleDateString(tx.createdDate);
        let obj = grouped.find((obj, i) => obj.txDate === txDate)
        if (!obj) 
        {
            grouped.push({txDate: txDate, totalVolume: tx.totalVolume, count: 1});
        }
        else
        {
            obj.totalVolume += tx.totalVolume;
            obj.count += 1;
        }
    });
    return grouped;
}

const fromISOToLocaleDateString = (isoDateString) => {
    return (new Date(isoDateString)).toLocaleDateString();
}