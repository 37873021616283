import React from 'react';
import kanalabslogo from '../Assests/kanalabslogo.png';
import twitter from '../Assests/twitter.png';
import Discord from '../Assests/Discord.png';
import Medium from '../Assests/Medium.png';
import Reddit from '../Assests/Reddit.png';
import linkledin from '../Assests/linkledin.png';
import downarrow3 from '../Assests/downarrow3.png';
import { FaTelegram } from 'react-icons/fa';
import footertelegram from '../Assests/footertelegram.png';
import { PieChart } from 'react-minimal-pie-chart';
import { useState, useEffect } from 'react';
import { swapDashboard } from '../Utiles/Helper';
import { useNavigate } from "react-router-dom"

const Dashboard = () => {
    const navigate = useNavigate();

    const [dashboardValue, setDashboardValue] = useState();
    const [lastDaySwap, setlastDaySwap] = useState({});
    const [cumilativeSwap, setcumilativeSwap] = useState({});
    const [lastDaySwapsameChain, setlastDaySwapsameChain] = useState({
        transactionCount: 0, Volume: 0
    });
    const [lastDaySwapcrosschain, setlastDaySwapcrosschain] = useState({
        transactionCount: 0, Volume: 0
    });


    useEffect(() => {
        // if(localStorage.getItem('kanaswapToken')){
        loadData()
        // }else{
        //     navigate('/login')
        // }
    }, []);
    async function logout() {
        localStorage.clear(
            navigate('/login')
        )
    }

    async function loadData() {
        let data = await swapDashboard()
        console.log("data here", data.data)
        if (!data.error) {
            setDashboardValue(data.data)
            if (data.data.lastDaySwap.length > 0) {
                setlastDaySwap(data.data.lastDaySwap[0])
            }
            if (data.data.cumilativeSwap.length > 0) {
                setcumilativeSwap(data.data.cumilativeSwap[0])
            }
            if (data.data.lastdaySwapByType.length > 0) {
                if (data.data.lastdaySwapByType[0].transactionType == "swap") {
                    setlastDaySwapsameChain(data.data.lastdaySwapByType[0])
                    if (data.data.lastdaySwapByType.length > 1) {
                        setlastDaySwapcrosschain(data.data.lastdaySwapByType[1])
                    }
                } else {
                    setlastDaySwapcrosschain(data.data.lastdaySwapByType[0])
                    if (data.data.lastdaySwapByType.length > 1) {
                        setlastDaySwapsameChain(data.data.lastdaySwapByType[1])
                    }
                }

            }

        }
    }

    return (
        <div className='w-full h-full'>
            <div className='shadow-lg mt-7 ml-14 flex border-t-gray-800 justify-between'>
                <img src={kanalabslogo} alt='' className='h-9 ml-14' />
                <button className='text-1xl text-[black] bg-green-300 px-6 py-2 mx-28 rounded-full mb-4 mt-1 loo font-bold' onClick={logout}>Logout</button>

            </div>

            <div className='borders ml-20 mr-20 py-10 mt-24 rounded loo'>
                <card className='border-collapse rounded border-solid border-slate-900'>
                    <h1 className='text-5xl text-black font-inter text-center mt-10'>Transactions Information ({lastDaySwap?.lastDay ? lastDaySwap['lastDay'] : ''})  </h1>
                    <div className='flex justify-around'>
                        <div className='flex-col text-center'>
                            <p className='text-2xl text-black font-inter mt-10 text-center'>Total Transactions count</p>
                            <p className='text-2xl text-black mt-3'>{lastDaySwap?.transactionCount ? lastDaySwap['transactionCount'] : 0}</p>
                        </div>
                        <div className='flex-col text-center'>
                            <p className='text-2xl text-black font-inter mt-10 text-center mr-4'>Total transaction volume in USD  </p>
                            <p className='text-2xl text-black mt-3'>{lastDaySwap?.Volume ? lastDaySwap['Volume'].toFixed(5) : 0}</p>
                        </div>
                        <div className='flex-col text-center'>
                            <p className='text-2xl text-black font-inter mt-10 text-center mr-14'>Kana Fees</p>
                            <p className='text-2xl text-black mt-3'>{lastDaySwap?.Volume ? (lastDaySwap['Volume'] * 0.1 / 100).toFixed(5) : 0}</p>
                        </div>
                    </div>
                </card>

            </div>
            {console.log("assign vlue", lastDaySwapcrosschain, lastDaySwapsameChain)}
            <div className='borders ml-20 mr-20 py-10 mt-24 rounded loo'>
                <card className='border-collapse rounded border-solid border-slate-900 '>
                    <h1 className='text-5xl text-black font-inter text-center mt-10 '>Same-chain & Cross-chain pie data</h1>
                    <div className='flex justify-between mt-6 px-40'>
                        <PieChart
                            data={[
                                { title: 'Same-chain', value: lastDaySwapsameChain?.transactionCount ? lastDaySwapsameChain['transactionCount'] : 0, color: '#E38627' },
                                { title: 'Cross-chain', value: lastDaySwapcrosschain?.transactionCount ? lastDaySwapcrosschain.transactionCount : 0, color: '#6A2135' },
                            ]} className="w-32"
                        />
                        <PieChart
                            data={[
                                { title: 'Same-chain', label: 'Five', value: lastDaySwapsameChain?.Volume ? Math.round(lastDaySwapsameChain['Volume']) : 0, color: '#E38627' },
                                { title: 'Cross-chain', label: 'six', value: lastDaySwapcrosschain?.Volume ? Math.round(lastDaySwapcrosschain?.Volume) : 0, color: '#6A2135' },
                            ]} className='w-32'
                        />
                        <PieChart
                            data={[
                                { title: 'Same-chain', label: 'Five', value: lastDaySwapsameChain?.Volume ? Math.round(lastDaySwapsameChain['Volume'] * 0.1 / 100) : 0, color: '#E38627' },
                                { title: 'Cross-chain', label: 'six', value: lastDaySwapsameChain?.Volume ? Math.round(lastDaySwapcrosschain?.Volume * 0.1 / 100) : 0, color: '#6A2135' },
                            ]} className='w-32'
                        />
                    </div>

                    <div className='flex justify-around px-2'>
                        <div className='flex-col text-center'>
                            <p className='text-2xl text-black font-inter mt-10 text-center'>Total Transactions count</p>
                            <div className='flex justify-between mt-4 px-9'>
                                <p className='text-2xl font-inter'>Same-chain:</p>
                                <p className='text-2xl text-black'>{lastDaySwapsameChain ? lastDaySwapsameChain['transactionCount'] : 0}</p>
                            </div>
                            <div className='flex justify-between mt-2 px-9'>
                                <p className='text-2xl font-inter'>Cross-chain:</p>
                                <p className='text-2xl text-black'>{lastDaySwapcrosschain ? lastDaySwapcrosschain['transactionCount'] : 0}</p>
                            </div>
                        </div>
                        <div className='flex-col text-center'>
                            <p className='text-2xl text-black font-inter mt-10 text-center mr-4'>Total transaction volume in USD  </p>
                            <div className='flex justify-between mt-4 px-9'>
                                <p className='text-2xl font-inter'>Same-chain:</p>
                                <p className='text-2xl text-black '>{lastDaySwapsameChain ? lastDaySwapsameChain['Volume'].toFixed(5) : 0}</p>
                            </div>
                            <div className='flex justify-between mt-2 px-9'>
                                <p className='text-2xl font-inter'>Cross-chain:</p>
                                <p className='text-2xl text-black'>{lastDaySwapcrosschain ? lastDaySwapcrosschain['Volume'].toFixed(5) : 0}</p>
                            </div>                        </div>
                        <div className='flex-col text-center'>
                            <p className='text-2xl text-black font-inter mt-10 text-center mr-14'>Kana Fees</p>
                            <div className='flex justify-between mt-4 px-9'>
                                <p className='text-2xl font-inter'>Same-chain:</p>
                                <p className='text-2xl text-black'>{lastDaySwapsameChain ? (lastDaySwapsameChain['Volume'] * 0.1 / 100).toFixed(5) : 0}</p>
                            </div>
                            <div className='flex justify-between mt-2 px-9'>
                                <p className='text-2xl font-inter'>Cross-chain:</p>
                                <p className='text-2xl text-black'>{lastDaySwapcrosschain ? (lastDaySwapcrosschain['Volume'] * 0.1 / 100).toFixed(5) : 0}</p>
                            </div>                        </div>
                    </div>

                </card>
            </div>
            {console.log('cumilya', cumilativeSwap)}
            <div className='borders ml-20 mr-20 py-10 mt-24 rounded loo px-2'>
                <card className='border-collapse rounded border-solid border-slate-900'>
                    <h1 className='text-5xl text-black font-inter text-center mt-10'>Cumulative data from start date </h1>
                    <div className='flex justify-around px-1'>
                        <div className='flex-col text-center px-9'>
                            <p className='text-2xl text-black font-inter mt-10 text-center'>Total Transactions count</p>
                            <p className='text-2xl text-black mt-3'>{cumilativeSwap?.transactionCount ? cumilativeSwap['transactionCount'] : 0}</p>
                        </div>
                        <div className='flex-col text-center px-9'>
                            <p className='text-2xl text-black font-inter mt-10 text-center mr-4'>Total transaction volume in USD  </p>
                            <p className='text-2xl text-black mt-3'>{cumilativeSwap?.Volume ? cumilativeSwap['Volume'].toFixed(5) : 0}</p>
                        </div>
                        <div className='flex-col text-center px-9'>
                            <p className='text-2xl text-black font-inter mt-10 text-center mr-20'>Kana Fees</p>
                            <p className='text-2xl text-black mt-3 mr-20'>{cumilativeSwap?.Volume ? (cumilativeSwap['Volume'] * 0.1 / 100).toFixed(5) : 0}</p>
                        </div>
                    </div>
                </card>

            </div>

            <div className='container'>
                <footer className='flex	justify-around mt-10 ml-2'>

                    <div >
                        <h1 className='text-2xl text-[white] font-raj font-semibold mt-14 ml-5'>Kana Labs</h1>
                        <div className='flex justify-between mt-4 h-6 mx-2'>
                            <img src={twitter} alt='' className='px-2 mt-2 h-6' />
                            <img src={Discord} alt='' className='px-2 mt-2 h-6' />
                            <img src={footertelegram} alt='' className='h-10 w-10' />
                        </div>
                    </div>


                    <div className='mt-6 ml-10'>
                        <ul className='my-8 ml-72'>
                            <li className='text-[white] cursor-pointer relative py-1'>About us</li>
                            <li className='text-[white] cursor-pointer relative py-1'>Careers</li>
                            <li className='text-[white] cursor-pointer relative py-1'>Press & brand</li>
                        </ul>
                    </div>

                    <div className='mt-6 '>
                        <ul className='my-8'>
                            <li className='text-[white] cursor-pointer relative py-1'>Blog</li>
                            <li className='text-[white] cursor-pointer relative py-1'>News</li>
                            <li className='text-[white] cursor-pointer relative py-1'>Docs</li>
                        </ul>
                    </div>

                    <div className='mt-6 ml-14'>
                        <ul className='my-8'>
                            <li className='text-[white] cursor-pointer relative py-1'>Privacy Policy</li>
                            <li className='text-[white] cursor-pointer relative py-1'>Terms & conditions</li>
                        </ul>
                    </div>
                </footer>

                <div className='bg-gray-900 flex justify-between mt-20 dfcv containerfooter'>
                    <h5 className='text-1xl text-[white] mb-14 ml-28'>© Copyright 2022 Kana Labs</h5>
                    <h5 className='text-1xl text-[white] mr-20'>hello@kanalabs.io</h5>
                </div>



            </div>


        </div >

    )
}

export default Dashboard
