import React from "react";

const ScoreCard = (props) => {
    return (
        <div className="flex flex-col items-center">
            <h2 className="text-3xl lg:text-5xl font-bold text-secondary">
                {!!props.prefix ? <sup className="text-xl lg:text-3xl">{props.prefix}&nbsp;</sup> : ""}
                {props.value}
            </h2>
            <p>{props.name}</p>
        </div>
    )
}

export default ScoreCard;