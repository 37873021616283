import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from '@react-spectrum/datepicker';
import { getLocalTimeZone, parseDate, today } from '@internationalized/date';
import { Provider, darkTheme } from '@adobe/react-spectrum';
import { fetchCustomReport } from '../Utiles/Helper';
import { GroupDataByDate } from '../Utiles/Utilities';
import ScoreCard from './ScoreCard';
import kanalabslogo from '../Assests/kanalabslogo.png';
import LogoutIcon  from '../Assests/logout-48.png';
import Table from './Table';
import Chart from './Chart';

const Dashboard = () => {
    const navigate = useNavigate();
    
    const [aggregateData, setAggregateData] = useState({});
    const [rows, setRows] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [dateRange, setDateRange] = useState({
        start: today(getLocalTimeZone()).subtract({days: 6}),
        end: today(getLocalTimeZone())
    });

    const columns = [
        {label: "Date & Time", dataIndex: "createdDate", key: "createdDate"},
        {label: "Transaction Type", dataIndex: "transactionType", key: "transactionType"},
        {label: "Token Name", dataIndex: "tokenName", key: "tokenName"},
        {label: "From Chain", dataIndex: "fromchain", key: "fromchain"},
        {label: "To Chain", dataIndex: "tochain", key: "tochain"},
        {label: "Volume", dataIndex: "totalVolume", key: "totalVolume"},
    ]

    useEffect(() => {
        getCustomReport();
    }, [dateRange]);

    useEffect(() => {
        if(chartData !== null)
        {
            setAggregateData(prevData => ({
                ...prevData,
                crossChainCount: chartData.crossChain.length,
                sameChainCount: chartData.sameChain.length
            }));
        }
    }, [chartData]);

    const logout = async () => {
        localStorage.clear(
            navigate('/login')
        );
    }
    const calculateAggregateData = (data) => {
        const aggregateVolume = data.reduce((totalVolume, tx) => totalVolume + tx.totalVolume, 0);
        const crossChainData = data.filter(tx => tx.crossChain);
        const sameChainData = data.filter(tx => !tx.crossChain);
        setChartData({
            crossChain: crossChainData,
            sameChain: sameChainData
        });
        setAggregateData({
            count: data.length,
            volume: aggregateVolume,
            fee: aggregateVolume / 1000
        })
    }

    const getCustomReport = async () => {
        let data = await fetchCustomReport({
            transactionType: "all",
            fromDate: dateRange.start.toString(),
            toDate: dateRange.end.add({days: 1}).toString()
        });
        data.sort((x, y) => {
            if (x.createdDate > y.createdDate) return 1;
            if (x.createdDate < y.createdDate) return -1;
            return 0;
        });
        data = data.map(tx => {
            return {
                ...tx,
                crossChain: tx.fromchain !== tx.tochain
            }
        });
        calculateAggregateData(data);
        setRows(data.map(tx => {
            return {
                ...tx,
                createdDate: new Date(tx.createdDate).toLocaleString()
            }
        }))
    }

    return (
        <div className='w-full xl:h-screen p-5 flex flex-col lg:flex-row gap-5 text-white'>
            <section className='sidebar flex flex-row lg:flex-col shrink justify-between custom-border p-5'>
                <img src={kanalabslogo} alt='KanaSwap logo' className='h-6' />
                <button 
                    className='text-white flex items-center justify-center w-fit lg:w-full h-5 brightness-75 hover:brightness-200 transition-all'
                    onClick={logout}
                >
                    Logout
                    <img src={LogoutIcon} alt="Logout icon" className='h-full ml-2' />
                </button>
            </section>
            <section className='main h-full flex flex-col grow gap-5 overflow-y-scroll'>
                <div className='action-bar flex justify-between items-center custom-border py-2 px-5'>
                    Welcome, KanaLabs Admin!
                    <div className='date-range-picker flex'>
                        <Provider theme={darkTheme} >
                        <DateRangePicker
                            value={dateRange}
                            onChange={setDateRange}
                            maxValue={today(getLocalTimeZone())}
                        />
                        </Provider>
                    </div>
                </div>
                <div className='action-bar grid grid-cols-3 custom-border px-5 py-2 divide-x'>
                    <ScoreCard 
                        value={aggregateData.count} 
                        name={"Transactions"} 
                    />
                    <ScoreCard 
                        value={aggregateData.volume && aggregateData.volume.toFixed(3)} 
                        name={"Total Volume"} prefix="$" 
                    />
                    <ScoreCard 
                        value={aggregateData.fee && aggregateData.fee.toFixed(3)} 
                        name={"Kana Fees"} 
                    />
                </div>
                <div className='flex flex-col-reverse xl:flex-row gap-5 h-full'>
                    <div className='data-board custom-border lg:overflow-clip relative'>
                        <Table columns={columns} rows={rows} dateRange={dateRange} />
                    </div>
                    <div className='basis-4/12 grow p-5 custom-border'>
                        {chartData && chartData.sameChain && chartData.crossChain &&
                            <Chart sameChain={chartData.sameChain} crossChain={chartData.crossChain} dataColumn="totalVolume" />
                        }
                    </div>
                    
                </div>
            </section>
        </div >

    )
}

export default Dashboard