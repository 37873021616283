import React, { useEffect, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { GroupDataByDate } from "../Utiles/Utilities";

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Chart = (props) => {
    let groupedSameChain = GroupDataByDate(props.sameChain);
    let groupedCrossChain = GroupDataByDate(props.crossChain);

    const [chart, setChart] = useState(null);

    groupedSameChain = groupedSameChain.map(tx => ({
        ...tx,
        y: tx[props.dataColumn],
        label: tx.txDate
    }));
    groupedCrossChain = groupedCrossChain.map(tx => ({
        ...tx,
        y: tx[props.dataColumn],
        label: tx.txDate
    }));

    useEffect(() => {
        if (chart) {
            console.log(chart);
            chart.themeOptions.backgroundColor = "transparent";
            chart.themeOptions.title.fontColor = "white";
            chart.themeOptions.axisX[0].titleFontColor = "white";
            chart.themeOptions.axisX[0].labelFontColor = "white";
            chart.themeOptions.axisY[0].titleFontColor = "white";
            chart.themeOptions.axisY[0].labelFontColor = "white";
            chart.themeOptions.legend.fontColor = "white";
        }
    }, [chart]);

    const options = {
        animationEnabled: true,
        title: {
            text: "Same Chain Vs. Cross Chain Transactions"
        },
        axisY: {
            title: "No. of transactions"
        },
        toolTip: {
            shared: true
        },
        data: [{
            type: "line",
            name: "Same Chain",
            showInLegend: true,
            dataPoints: groupedSameChain
        }, {
            type: "line",
            name: "Cross Chain",
            showInLegend: true,
            dataPoints: groupedCrossChain
        }]
    }

    return (
        <CanvasJSChart options={options} onRef={ref => setChart(ref)} containerProps={{height: "300px"}} />
    )
}

export default Chart;